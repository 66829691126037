import { render, staticRenderFns } from "./Instructivo.vue?vue&type=template&id=071e76a1&scoped=true"
import script from "./Instructivo.vue?vue&type=script&lang=js"
export * from "./Instructivo.vue?vue&type=script&lang=js"
import style0 from "../assets/css/components/instructivo.css?vue&type=style&index=0&id=071e76a1&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071e76a1",
  null
  
)

export default component.exports