<template>
  <div class="hero-options-container">

    <div
      v-for="(module, index) in getToolsFiltered"
      class="option-card"
      :key="index"
      :class="module.class"
      @click="module.onClick"
    >
      <span class="icon material-symbols-outlined">
        {{module.icon}}
      </span>
      <p v-html="module.text"></p>
    </div>

    <div @click="openAllMyAppsModal" class="option-card herramientas-card">
      <div class="tools-card-header">
        <img
          class="tools_icon"
          src="../../assets/img/landing/todas_herramientas.png"
          alt="tools icon"
        />
        <Icon name="add" class="icon_lg tools_icon_add" />
      </div>
      <p>Todas Mis Apps</p>
    </div>
    <SelectClientPopup
      ref="selectClientPopup"
      :title="goToTitle"
      :modulo="irAModulo"
      :callback="soldToPopupCallback"
    ></SelectClientPopup>
  </div>
</template>
<script>
import SelectClientPopup from "../SelectClientPopup.vue";
import helpers from "@/mixins/helpers";
import Icon from "@/components/Icon.vue";
import toolsMixin from "@/mixins/toolsMixin";

export default {
  components: { SelectClientPopup, Icon },
  mixins: [helpers, toolsMixin],
  data() {
    return {
      miTiendaToken: false,
      irAModulo: null,
      goToTitle: null
    };
  },
  mounted() {
    this.getMITiendaBASFURL();
  },
  methods: {
    // Llamar a la función desde el componente secundario
    openAllMyAppsModal() {
      this.$eventHub.$emit("open-all-my-apps-modal", true); // Emitir evento para abrir el modal
    },
    getMITiendaBASFURL() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/mitienda")
        .then(function(response) {
          that.miTiendaToken = response.data;
        })
        .catch(function() {});
    },
    soldToPopupCallback() {
      this.goToModule(this.irAModulo);
    }
  }
};
</script>
<style src="../../assets/css/components/hero-tools.css"></style>
