<template>
  <div v-infocus="'showElement'" class="hidden hidden-down instructivo-container">
    <button
      v-if="getRolUsuario()"
      class="instructivo__action-button instructivo__delete-button"
      @click.stop="openDeleteModal()"
    >
      <Icon name="delete" style="fontSize: 1.5rem;" color="000"/>
    </button>
    <div class="instructivo-image__container" @click="verVideo()">
      <img
        class="instructivo-image"
        :src="getImage()"
        alt="instructivo thumbnail"
      />
      <div class="intructivo-image__hover">
        <Icon name="play_arrow" style="fontSize: 2rem;" color="fff" fill />
      </div>
    </div>
    <div class="instructivo-info">
      <h2>{{ instructivo.titulo }}</h2>
      <p>{{ instructivo.descripcion }}</p>
      <div class="instructivo-buttons--container">
        <button v-if="instructivo.materiales.length > 0" class="intructivo-button--material" @click="descargarMateriales()">
          Material
          <Icon name="download" style="fontSize: 1.2rem;" color="00a3d9" />
        </button>
        <button class="intructivo-button--video" @click="verVideo()">
          Ver video
          <Icon name="play_arrow" style="fontSize: 1.2rem;" color="fff" fill />
        </button>
      </div>
    </div>
    <!-- Delete modal -->
    <div
      v-if="deleteModalIsOpen"
      class="delete-instructivo-modal__background"
      @click="closeDeleteModal()"
    >
      <div class="delete-instructivo-modal__container" @click.stop>
        <div class="delete-instructivo-modal__header">
          <p>Eliminar</p>
          <Icon name="close" style="fontSize: 1.2rem;" color="000" :click="closeDeleteModal" class="delete-instructivo-modal__close-icon" pointer/>
        </div>
        <p>¿Estás seguro de eliminar la publicación ?</p>
        <div class="delete-instructivo-modal__buttons">
          <button
            class="delete-instructivo-modal__cancelar"
            @click="closeDeleteModal()"
          >
            Cancelar
          </button>
          <button
            class="delete-instructivo-modal__eliminar"
            @click="deleteInstructivo()"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";

export default {
  components: { Icon },
  props: {
    instructivo: Object
  },
  data() {
    return {
      deleteModalIsOpen: false
    };
  },
  methods: {
    getImage() {
      return this.instructivo.imagen
        ? this.$localurl + "/" + this.instructivo.imagen
        : require("../assets/img/default.png");
    },
    openDeleteModal() {
      this.deleteModalIsOpen = true;
    },
    closeDeleteModal() {
      this.deleteModalIsOpen = false;
    },
    deleteInstructivo() {
      const that = this;
      this.$axios
        .delete(this.$localurl + "/api/instructivo/" + this.instructivo.id)
        .then(function() {
          that.$eventHub.$emit("instructivo-nuevo");
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    getRolUsuario() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance") ||
        this.$usuario.email == "ana-karen.spioussas@basf.com" ||
        this.$usuario.email == "maria-ana.laguzzi@basf.com" ||
        this.$usuario.email == "gustavo.saldania@basf.com" ||
        this.$usuario.email == "jeronimo.carmody-fernandez@basf.com"
      ) {
        return true;
      } else {
        return false;
      }
    },
    descargarMateriales(){
      this.instructivo.materiales.forEach(url => {
        setTimeout(() => {
          window.open(this.$localurl + url, "_blank").focus();
        }, 500);
    });
    },
    verVideo() {
      window.open(this.instructivo.link, "_blank").focus();
    }
  }
};
</script>
<style scoped src="../assets/css/components/instructivo.css"></style>
