<script>
import AppCard from "./AppCard.vue";
import AddToolCard from "./AddToolCard.vue";
import { ordenarJson } from "@/assets/js/general.js";
import SelectClientPopup from "../SelectClientPopup.vue";
import helpers from "@/mixins/helpers";
import Icon from "@/components/Icon.vue";
import toolsMixin from "@/mixins/toolsMixin";

export default {
  components: { AppCard, AddToolCard, SelectClientPopup, Icon },
  mixins: [helpers, toolsMixin],
  data() {
    return {
      isOpen: false,
      myStoreToken: false,
      configuration: {},
      moduleSelected: null,
      goToTitle: null
    };
  },
  mounted() {
    this.getConfiguration();
    this.getMITiendaBASFURL();
    this.$eventHub.$on("recorrido-listo", () => this.getConfiguration());
    this.$eventHub.$on("herramienta-nueva", herramienta =>
      this.newTool(herramienta)
    );
    this.$eventHub.$on("eliminar-herramienta", herramienta => {
      this.removeTool(herramienta);
      this.$eventHub.$emit("delete-tool-modal-open", false);
    });
    // Escuchar el evento para abrir el modal
    this.$eventHub.$on("open-all-my-apps-modal", shouldOpen => {
      this.isOpen = shouldOpen;
    });
  },
  updated() {
    if (this.isOpen) {
      document.body.classList.add("body-no-scroll");
    }
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      // Elimina la clase para habilitar nuevamente el scroll
      document.body.classList.remove("body-no-scroll");
    },
    getMITiendaBASFURL() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/mitienda")
        .then(function(response) {
          that.myStoreToken = response.data;
        })
        .catch(function() {});
    },
    openAddNewToolModal() {
      this.$eventHub.$emit("open-add-new-tool-modal", true);
      document.body.classList.add("body-no-scroll");
    }, // Getting the configuration from the server.
    newTool(tool) {
      if (this.configuration.herramientas.some(h => h.id == tool.id)) {
        this.configuration.herramientas = this.configuration.herramientas.filter(
          h => h.id != tool.id
        );
      }
      this.configuration.herramientas.push(tool);
      this.saveSettings();
    }, // Making a PUT request to the server and sending the data in the configuracion object.
    saveSettings() {
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/configuracion", this.configuration)
        .then(function(response) {
          that.configuration = {};
          that.$nextTick(() => {
            that.configuration = response.data;
          });
          this.getConfiguration();
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    postMetric(module) {
      this.$axios
        .post(this.$localurl + "/api/metrica/" + module)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    goTo(module) {
      this.goToModule(module);
    },
    goToMetric() {
      this.$router.push("/metricas");
    },
    removeTool(tool) {
      this.configuration.herramientas = this.configuration.herramientas.filter(
        h => h.id != tool.id
      );
      this.saveSettings();
    },
    gotoPermissions() {
      this.$router.push("/permisos");
    },
    getConfiguration() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/configuracion")
        .then(function(response) {
          if (response.data != "") {
            that.configuration = response.data;
            that.configuration.herramientas = that.sortJson(
              that.configuration.herramientas,
              "nombre",
              true
            );
          }
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    goToTool(link) {
      console.log(link);
    },
    sortJson: function(json, prop, asc) {
      return ordenarJson(json, prop, asc);
    },
    soldToPopupCallback() {
      this.goTo(this.moduleSelected);
    }
  }
};
</script>

<template>
  <transition name="fade-fast">
    <div v-if="isOpen">
      <div class="all-my-apps-modal__background" @click="closeModal"></div>
      <div class="all-my-apps-modal__container">
        <div class="all-my-apps-modal__header">
          <p class="all-my-apps-modal__title">Todas Mis Apps</p>
          <Icon name="close" :click="closeModal" :pointer="true" />
        </div>
        <div class="all-my-apps-modal__cards">
          <div
            v-for="(module, index) in getToolsFiltered"
            class="option-card"
            :key="index"
            :class="module.class"
            @click="module.onClick"
          >
            <span class="icon material-symbols-outlined">
              {{module.icon}}
            </span>
            <p v-html="module.text"></p>
          </div>

          <AppCard
            v-for="tool in configuration.herramientas"
            :herramienta="tool"
            :key="tool.id"
            @click="goToTool(tool.link)"
          />

          <AddToolCard />
        </div>
      </div>
      <SelectClientPopup
        ref="selectClientPopup"
        :title="goToTitle"
        :modulo="moduleSelected"
        :callback="soldToPopupCallback"
      ></SelectClientPopup>
    </div>
  </transition>
</template>
<style src="../../assets/css/components/all-my-apps-modal.css"></style>
