<script>
import Icon from "@/components/Icon.vue";
export default {
  components: { Icon },
  methods: {
    openAddNewToolModal() {
      this.$eventHub.$emit("open-add-new-tool-modal", true);
      this.$eventHub.$emit("open-all-my-apps-modal", false);
    }
  }
};
</script>
<template>
  <div class="add-tool-card__container" @click="openAddNewToolModal">
    <Icon name="add" color="fff" class="add-tool-card__icon" />
    <p class="add-tool-card__text">Agregar <br />herramienta</p>
  </div>
</template>
<style src="../../assets/css/components/add-tool-card.css"></style>
